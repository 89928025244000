import type { CoreFetch } from '~/types/globalDeclarations/CoreFetch'
import type { Sentry } from '~/types/Sentry'
import type { SeoListing } from '~/types/seoList'

let cache: SeoListing[] | null = null

export const provideFetchSeoGenericListHOF =
  (coreFetch: CoreFetch, $sentry?: Sentry) =>
  async (): Promise<SeoListing[]> => {
    if (!cache) {
      try {
        cache = await coreFetch.$get<SeoListing[]>('/seo/generic_lists/')
      } catch (err) {
        $sentry?.captureException(err)
        return [] as SeoListing[]
      }
    }

    return cache
  }
